<div class="bg-white bg-light-30p-hover border rounded-3 p-2 pointer">
  <div class="row align-items-center">
    <div class="col-auto d-flex align-items-center">
      <hiji-user-avatar
        style="width: 60px; height: 60px"
        [idUser]="exchange.idUserObserved === idUserLogged ? exchange.idUserManager : exchange.idUserObserved"
        [avatarExtension]="exchange.idUserObserved === idUserLogged ? exchange.avatarExtensionManager : exchange.avatarExtensionObserved"
        [defaultBlueAvatar]="true"
      ></hiji-user-avatar>
    </div>
    <div class="col">
      <h6>
        {{ 'visualize.share-card.Échange du' | translate }} {{ exchange.creationDate | localizedDate }}
        <br />
        {{ ('visualize.share-card.avec' | translate) + ' ' + (exchange.idUserObserved === idUserLogged ? exchange.userManager : exchange.userObserved) }}

        <ng-container *ngFor="let repo of exchange.repositories">
          <br />
          <span class="my-fs-2 text-muted">{{ repo | ms }}</span>
        </ng-container>
      </h6>
    </div>
  </div>
</div>
