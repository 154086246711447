<a class="text-body pointer" (click)="sendFBS(solicitation)">
  <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
    <div class="row g-1 px-2 py-3 flex-grow-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-comment-alt fa-flip-horizontal fa-2x text-tertiary align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center no-select">
        <div class="row">
          <div class="col-auto">
            <b>{{ solicitation.firstName }} {{ 'fbs.solicitation-card.sollicite votre feedback' | translate }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col my-fs-2">{{ "fbs.solicitation-card.L'objet de sa demande" | translate }}&nbsp;: {{ solicitation.subject | truncate: 70 : true }}</div>
        </div>
        <div class="row">
          <div class="col align-self-baseline text-end my-fs-1 me-2">
            <i class="fa-light fa-clock"></i>
            {{ solicitation.creationDate | localizedDate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
