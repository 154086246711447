<a class="text-body" [routerLink]="['/teamboard', 'create']">
  <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
    <div class="row g-1 px-2 py-3 flex-grow-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-users-rectangle fa-2x text-tertiary align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            <b>{{ "teamboard.home-card.Créer votre premier dashboard d'équipe" | translate }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
