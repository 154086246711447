<div class="bg-white bg-light-30p-hover border rounded-3">
  <div class="row g-1">
    <div class="col-auto d-flex align-items-center">
      <i class="me-2 fa-light fa-clipboard-list fa-2x text-tertiary p-4"></i>
    </div>
    <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
      <div class="row">
        <div class="col-auto fw-bold">
          {{ inquiry.name | ms }}
        </div>
      </div>
      <div class="row">
        <div class="col-auto" *ngIf="!DateUtils.isPast(inquiry.endDate)">
          {{ 'visualize.campaign360-card.Termine le' | translate }}
          <b>{{ inquiry.endDate | localizedDate }}</b>
        </div>
        <div class="col-auto" *ngIf="DateUtils.isPast(inquiry.endDate)">
          {{ 'visualize.campaign360-card.Terminé depuis le' | translate }}
          <b>{{ inquiry.endDate | localizedDate }}</b>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <span class="me-2">
            <ng-container *ngIf="inquiry.nbTargets <= 1">{{ inquiry.nbTargets }} {{ 'inquiry.gestion.infos.Participant' | translate }}</ng-container>
            <ng-container *ngIf="inquiry.nbTargets > 1">{{ inquiry.nbTargets }} {{ 'inquiry.gestion.infos.Participants' | translate }}</ng-container>
          </span>
          <span class="me-2">
            <ng-container *ngIf="inquiry.nbAnswers <= 1">{{ inquiry.nbAnswers }} {{ 'inquiry.gestion.infos.Répondant' | translate }}</ng-container>
            <ng-container *ngIf="inquiry.nbAnswers > 1">{{ inquiry.nbAnswers }} {{ 'inquiry.gestion.infos.Répondants' | translate }}</ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
