<div class="bg-white bg-light-30p-hover border rounded-3 p-4">
  <div class="row g-1">
    <div class="col-auto d-flex align-items-center me-3">
      <i class="fa-light fa-users fa-2x text-tertiary"></i>
    </div>
    <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
      <div class="row" *ngIf="group.groupType !== GroupType.GROUP">
        <div class="col-auto">{{ 'common.enum.GroupType.' + group.groupType.lib | translate }} ({{ group.users.length }})</div>
      </div>
      <div class="row" *ngIf="group.groupType === GroupType.GROUP">
        <div class="col-auto">{{ 'group.card.Équipe' | translate }} {{ group.groupName }} ({{ group.users.length }})</div>
      </div>
    </div>
  </div>
</div>
