<a
  class="text-body pointer"
  [routerLink]="
    eva.user.idUser !== authService.userBundle.user.idUser
      ? [
          '/formation',
          eva.idFormation,
          string_to_slug(eva.formationName | ms),
          'session',
          eva.idFormationSession,
          string_to_slug(eva.formationSessionName),
          eva.user.idUser,
          string_to_slug(eva.user.firstName + ' ' + eva.user.lastName),
          'manager',
          'eva',
        ]
      : null
  "
  (click)="showToastIfUserIsTargetEVA()"
>
  <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
    <div class="row g-1 px-2 py-3 flex-grow-1">
      <div class="col-auto d-flex align-items-center justify-content-center align-icon">
        <hiji-user-avatar
          style="width: 60px; height: 60px"
          [idUser]="eva.user.idUser"
          [avatarExtension]="eva.user.avatarExtension"
          [defaultBlueAvatar]="true"
        ></hiji-user-avatar>
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto fw-bold" *ngIf="eva.user.idUser !== authService.userBundle.user.idUser">
            {{ ('mif.Entretien post-formation {{ formation }} avec {{ user }}' | translate: { user: eva.user.firstName + ' ' + eva.user.lastName, formation:
            eva.formationName | ms })}}
          </div>
          <div class="col-auto fw-bold" *ngIf="eva.user.idUser === authService.userBundle.user.idUser">
            {{ 'mif.Entretien post-formation {{ formation }} avec un manager' | translate: { formation: eva.formationName | ms } }}
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <span class="fw-bold" [class.text-danger]="warningEndDate">{{ 'global.le {{date}}' | translate:{date:(eva.dateEVA | localizedDate)} }}</span>
            <i class="fa-light fa-exclamation-triangle text-danger ms-2" *ngIf="warningEndDate"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
