<div class="container-fluid bg-light-30p h-100" style="overflow-y: hidden">
  <i class="fa-light fa-spinner spinning" *ngIf="loading"></i>
  <ng-container *ngIf="!loading">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 mt-3 animate__animated animate__bounceInUp">
        <hiji-user-card [user]="data.me" [showCallToAction]="true"></hiji-user-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <a class="text-body" routerLink="skillsup/coaching/{{ data.me.idUser }}/{{ string_to_slug(data.me.firstName + '-' + data.me.lastName) }}">
          <div class="bg-white border rounded-3">
            <div class="row g-1">
              <div class="col-auto d-flex align-icon align-items-center justify-content-center" style="height: 80px">
                <i class="fa-light fa-people-group fa-3x text-tertiary p-4"></i>
              </div>
              <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-auto text-uppercase">
                    <b>{{ 'specific.skillsup.home.Coachings' | translate }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <a class="text-body" routerLink="/certification">
          <div class="bg-white border rounded-3">
            <div class="row g-1">
              <div class="col-auto d-flex align-icon align-items-center justify-content-center" style="height: 80px">
                <i class="fa-light fa-award-simple fa-3x text-tertiary p-4"></i>
              </div>
              <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-auto text-uppercase">
                    <b>{{ 'menu.specific.skillsup.Assessments' | translate }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngIf="encouragePicture">
        <hiji-encourage-picture-card></hiji-encourage-picture-card>
      </div>
    </div>
  </ng-container>
</div>
