<a
  class="text-body"
  [routerLink]="[
    '/inquiry360/answer',
    inquiryToAnswer.idInquiry,
    string_to_slug(inquiryToAnswer.firstName + '-' + inquiryToAnswer.lastName),
    string_to_slug(inquiryToAnswer.name | ms),
  ]"
>
  <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
    <div class="row g-1 px-2 py-3 flex-grow-1">
      <div class="col-auto d-flex align-items-center justify-content-center align-icon">
        <hiji-user-avatar
          style="width: 60px; height: 60px"
          [idUser]="inquiryToAnswer.idUser"
          [avatarExtension]="inquiryToAnswer.avatarExtension"
          [defaultBlueAvatar]="true"
        ></hiji-user-avatar>
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto fw-bold">{{ inquiryToAnswer.name | ms }}</div>
        </div>
        <div class="row">
          <div class="col-auto">
            <span class="fw-bold">{{ inquiryToAnswer.firstName }} {{ inquiryToAnswer.lastName }}</span>
            {{ 'inquiry360-answer-card.vous sollicite' | translate }}
          </div>
        </div>
        <div class="row" *ngIf="!inquiryToAnswer.draft">
          <div class="col-auto">
            {{ 'inquiry360-answer-card.Répondre avant le' | translate }}
            <span class="fw-bold" [class.text-danger]="warningEndDate">{{ inquiryToAnswer.endDate | localizedDate }}</span>
            <i class="fa-light fa-exclamation-triangle text-danger ms-2" *ngIf="warningEndDate"></i>
          </div>
        </div>
        <div class="row" *ngIf="inquiryToAnswer.draft">
          <div class="col-auto text-success">
            <i class="fa-light fa-clipboard me-1"></i>
            {{ 'inquiry360-answer-card.En brouillon, à envoyer avant le' | translate }}
            <span class="fw-bold" [class.text-danger]="warningEndDate">{{ inquiryToAnswer.endDate | localizedDate }}</span>
            <i class="fa-light fa-exclamation-triangle text-danger ms-2" *ngIf="warningEndDate"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
