<!-- <button class="btn btn-primary" (click)="testBadge()">test badge</button>
<button class="btn btn-primary" (click)="testRegister()">testRegister</button> -->

<div class="container">
  <div class="row">
    <div class="col">
      <h1>Ceci est un test h1</h1>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <h2>Ceci est un test h2</h2>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <h3>Ceci est un test h3</h3>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <h4>Ceci est un test h4</h4>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <h5>Ceci est un test h5</h5>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <h6>Ceci est un test h6</h6>
    </div>
  </div>
  <div class="row align-items-sm-start mt-2">
    <div class="col">
      <i>Italic</i>
    </div>
    <div class="col">
      <b>Bold</b>
    </div>
    <div class="col">
      <u>Underline</u>
    </div>
    <div class="col">
      <i><b>Italic and bold</b></i>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <p>Boutons</p>
      <div class="d-flex gap-2 flex-wrap">
        <button class="btn btn-primary" type="button">Primary</button>
        <button class="btn btn-secondary" type="button">Secondary</button>
        <button class="btn btn-tertiary" type="button">Tertiary</button>
        <button class="btn btn-success" type="button">Success</button>
        <button class="btn btn-danger" type="button">Danger</button>
        <button class="btn btn-warning" type="button">Warning</button>
        <button class="btn btn-info" type="button">Info</button>
        <button class="btn btn-light" type="button">Light</button>
        <button class="btn btn-light-30p" type="button">Light 30p</button>
        <button class="btn btn-medium" type="button">Medium</button>
        <button class="btn btn-dark" type="button">Dark</button>
        <button class="btn btn-gris-hiji" type="button">gris hiji</button>

        <button class="btn btn-link" type="button">Link</button>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <p>Alerts</p>
      <div class="alert alert-primary" role="alert">A simple primary alert—check it out!</div>
      <div class="alert alert-secondary mt-2" role="alert">A simple secondary alert—check it out!</div>
      <div class="alert alert-tertiary mt-2" role="alert">A simple tertiary alert—check it out!</div>
      <div class="alert alert-success mt-2" role="alert">A simple success alert—check it out!</div>
      <div class="alert alert-danger mt-2" role="alert">A simple danger alert—check it out!</div>
      <div class="alert alert-warning mt-2" role="alert">A simple warning alert—check it out!</div>
      <div class="alert alert-info mt-2" role="alert">A simple info alert—check it out!</div>
      <div class="alert alert-light mt-2" role="alert">A simple light alert—check it out!</div>
      <div class="alert alert-light-30p mt-2" role="alert">A simple light-30p alert—check it out!</div>
      <div class="alert alert-medium mt-2" role="alert">A simple medium alert—check it out!</div>
      <div class="alert alert-dark mt-2" role="alert">A simple dark alert—check it out!</div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <p>Couleurs hiji</p>
      <div class="d-flex gap-2 flex-wrap">
        <button class="btn hiji-bg-global">global</button>
        <button class="btn hiji-bg-auto-observation">auto-observation</button>
        <button class="btn hiji-bg-manager">manager</button>
        <button class="btn hiji-bg-equipe">equipe</button>
        <button class="btn hiji-bg-pair">pair</button>
        <button class="btn hiji-bg-collaborateur">collaborateur</button>
        <button class="btn hiji-bg-externe">externe</button>
        <button class="btn hiji-bg-moyenne">moyenne</button>
        <button class="btn hiji-bg-enseigne">enseigne</button>
        <button class="btn hiji-bg-societe">societe</button>
        <button class="btn hiji-bg-region">region</button>
        <button class="btn hiji-bg-etablissement">etablissement</button>
        <button class="btn hiji-bg-cible">cible</button>
        <button class="btn hiji-bg-acquisition-before">acquisition-before</button>
        <button class="btn hiji-bg-acquisition-after">acquisition-after</button>
        <button class="btn hiji-bg-actions-j10">actions-j10</button>
        <button class="btn hiji-bg-actions-j42">actions-j42</button>
        <button class="btn hiji-bg-actions-manager">actions-manager</button>
        <button class="btn hiji-bg-rank-1">rank-1</button>
        <button class="btn hiji-bg-rank-2">rank-2</button>
        <button class="btn hiji-bg-rank-3">rank-3</button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <input class="form-control" type="text" [(ngModel)]="testSignal" placeholder="label" />
    </div>
    <div class="col">
      <button class="btn btn-primary" (click)="test()">test signal</button>
    </div>
  </div>
  <div class="row">
    @if (show()) {
      <div class="col">affiché si true</div>
    }
    <div class="col">
      <button class="btn btn-primary" (click)="show.set(true)">show</button>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <hiji-radar-chart [data]="data"></hiji-radar-chart>
    </div>
  </div>
</div>
